@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.container {
  margin: 27px 0 36px;
  display: flex;
}

.container.hasFullRowOfTiles {
  justify-content: space-between;
}

.tile {
  border: 1px solid #ddd;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  max-width: 278px;
  margin-right: 18px;
  padding: 9px;
  text-decoration: none;
  display: flex;
}

@media (width >= 769px) {
  .tile {
    position: relative;
  }

  .tile:after {
    content: "";
    opacity: 0;
    background: #c2a661;
    width: 100%;
    height: 100%;
    transition: opacity .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tile:hover:after {
    opacity: .25;
  }
}

.tile:nth-child(5) {
  margin-right: 0;
}

.imageWrapper {
  width: 100%;
  height: 130px;
  position: relative;
}

.image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.title {
  letter-spacing: 0;
  text-align: center;
  color: #222;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}
