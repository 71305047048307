.item {
  align-self: stretch;
}

.item:first-child {
  padding-left: 0;
}

.item:last-child {
  padding-right: 0;
}
