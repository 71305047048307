.dot {
  display: inline-block;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
}
.dot:hover, .dot.activeDot {
  background-color: #a48e45;
}

.medium {
  width: 10px;
  height: 10px;
}

.small {
  width: 5px;
  height: 5px;
}

.dark:hover, .dark.activeDot {
  background-color: #222;
}