.container {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
}

.imageContainer {
  position: relative;
  width: 310px;
  height: 230px;
}

.image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.heading {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 18px 0 5px;
}

.subHeading {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.fullWidth {
  width: 100%;
}