.relatedCreatorsWrapper {
  text-align: center;
  margin: 27px 0 72px;
}

.relatedCreatorsHeader {
  margin-bottom: 9px;
}

.relatedCreators {
  display: flex;
  flex-wrap: wrap;
}

.viewMoreButton {
  margin-top: 18px;
  width: 200px;
}