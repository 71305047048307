.container {
  color: #222;
  background-color: #f3f3f3;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  max-width: 255px;
  margin-right: 18px;
  padding: 27px;
  display: flex;
}

.container:last-child {
  margin-right: 0;
}

.title {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.divider {
  border-bottom: 2px solid #222;
  width: 70px;
  margin: 18px 0;
}

.regularFontBody {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.smallFontBody {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.viewMoreButton {
  margin: 18px 0;
}
