.relatedCreatorTile {
  border: 1px solid #ddd;
  flex-direction: column;
  flex-basis: calc(33.3333% - 36px);
  justify-content: space-evenly;
  margin: 18px;
  padding: 27px 54px;
  display: flex;
}

@media (width <= 1024px) {
  .relatedCreatorTile {
    padding: 27px;
  }
}

.relatedCreatorReview, .relatedCreatorName {
  margin-bottom: 18px;
}

.relatedCreatorName {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.followRelatedCreator {
  margin-bottom: 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  position: relative;
}

.relatedCreatorItems {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.relatedCreatorItem:first-child {
  margin-right: 18px;
}

.relatedCreatorItem:last-child {
  margin-left: 18px;
}

@media (width <= 1024px) {
  .relatedCreatorItem:first-child {
    margin-right: 9px;
  }

  .relatedCreatorItem:last-child {
    margin-left: 9px;
  }
}

.relatedCreatorItemImage {
  max-width: 100%;
  max-height: 130px;
}

.shopAllTitle {
  margin-top: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.hideTile {
  display: none;
}

.placeholderClass {
  background-color: #fbfbfb;
  min-width: 100px;
  min-height: 100px;
}
