@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.container {
  margin-bottom: 45px;
}

.itemContainer {
  border: 1px solid #ddd;
  flex-direction: row;
  flex: 1;
  align-self: stretch;
  margin: 0 9px;
  padding: 18px;
  text-decoration: none;
  display: flex;
}

@media (width >= 769px) {
  .itemContainer {
    position: relative;
  }

  .itemContainer:after {
    content: "";
    opacity: 0;
    background: #c2a661;
    width: 100%;
    height: 100%;
    transition: opacity .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .itemContainer:hover:after {
    opacity: .25;
  }
}

@media (width <= 1024px) {
  .itemLimit:nth-child(n+4) {
    display: none;
  }
}

.loading {
  opacity: .3;
  pointer-events: none;
}

.imageWrapper {
  flex-shrink: 0;
  width: auto;
  max-width: 33%;
  height: auto;
  margin-right: 18px;
  display: flex;
  position: relative;
}

.title {
  letter-spacing: -.5px;
  letter-spacing: 0;
  text-align: left;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}

.title, .title:hover {
  color: #222;
}

.isL1LandingPage .itemContainer {
  border: none;
  flex-direction: column;
}

.isL1LandingPage .itemContainer:hover:after {
  opacity: 0;
}

.isL1LandingPage .imageWrapper {
  max-width: none;
  height: 120px;
  margin-right: 0;
}

.isL1LandingPage .title {
  text-align: center;
  align-items: flex-start;
  margin-top: 18px;
}

.isL1LandingPage .image {
  max-height: 100%;
}

.image {
  max-width: 100%;
  max-height: 72px;
  margin: auto;
  position: relative;
  inset: 0;
}
