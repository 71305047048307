.suggestion {
  display: inline-block;
  margin-right: 6px;
}
.suggestion .suggestionTitle {
  margin-right: 6px;
}
.suggestion:not(:first-child):not(:last-child):after {
  content: ",";
}

.suggestion {
  display: inline-block;
}