/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 300px;
}
.wrapper.reverse {
  flex-direction: row-reverse;
}

.staticContainer,
.htmlContainer {
  max-width: 1440px;
  position: relative;
}

.staticContainer {
  display: block;
}

.htmlContainer.border {
  border: thin solid #ddd;
}

.staticText {
  position: absolute;
  height: 100%;
  width: 40%;
}

.termsLink {
  color: #444;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  color: #666;
  position: absolute;
  margin-left: 54px;
  bottom: 18px;
}
.termsLink:hover {
  color: #444;
  text-decoration: underline;
}