.wrapper {
  background-color: #f3f3f3;
  width: 100vw;
  margin: 18px 0 72px;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: hidden;
}

.content {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  padding: 36px 0;
  display: flex;
}

.header {
  letter-spacing: -.5px;
  text-align: center;
  margin: 72px 0 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.viewMoreButtonWrapper {
  justify-content: center;
  padding-bottom: 36px;
  display: flex;
}

.viewMoreButton {
  width: 200px;
}
