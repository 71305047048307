@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.tile {
  text-decoration: none;
  border: 1px solid #e8dfcd;
  display: block;
  flex: 1;
  margin-right: 18px;
}
.tile:last-child {
  margin-right: 0;
}
.tile:hover .tileOverlay {
  background: #222;
  opacity: 0.7;
}
.tile:hover .tileHoverMessage {
  display: block;
}

.overlay {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageContainer {
  align-items: center;
  display: flex;
  height: 180px;
  justify-content: center;
  position: relative;
  width: 100%;
}
@media (max-width: 1024px) {
  .imageContainer {
    height: 108px;
  }
}

.image {
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  max-height: 90%;
  max-width: 90%;
}

.bgImage {
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.tileOverlay {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.priceBucketTileOverlay {
  composes: tileOverlay;
  background: #c2a661;
  opacity: 0.25;
}

.tileHoverMessage {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  z-index: 10;
  color: #fff;
  display: none;
  max-width: 65%;
  position: absolute;
  text-align: center;
}

.commonTileHoverMessage {
  composes: tileHoverMessage;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.priceBucketTileHoverMessage {
  composes: tileHoverMessage;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
}

.displayName {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  align-items: center;
  color: #222;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 100%;
}

.icon {
  display: block;
  height: 40px;
  margin: auto;
  padding-bottom: 9px;
  width: 40px;
}