@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.title {
  margin-bottom: 27px;
}

.title.loading {
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50% 51%, #ddd 100%);
  background-size: 400% 400%;
  width: 140px;
  height: 30px;
  animation-name: shimmerLoadingBackground;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}

.tileContainer {
  text-align: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.tile {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  min-width: 305px;
  max-width: 426px;
  height: 213px;
  margin-right: 18px;
  padding: 27px;
  text-decoration: none;
}

.tile:last-child {
  margin-right: 0;
}

.tile.loading {
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50% 51%, #ddd 100%);
  background-size: 400% 400%;
  padding: 0;
  animation-name: shimmerLoadingBackground;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}

.images {
  width: 100%;
  max-width: 319px;
  height: 86px;
  margin: 0 auto 27px;
  display: flex;
}

.image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.tileTitle {
  letter-spacing: -.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  display: block;
  position: relative;
  overflow: hidden;
}

.shopAll {
  margin-bottom: 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-decoration: underline;
}
