/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.headerWrapper {
  text-align: center;
  margin-bottom: 27px;
}

.header {
  margin: 0;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.subHeader {
  margin: 0;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.topModule {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.bannerTileContainer {
  flex: 1;
  max-width: 658px;
  margin: 0 36px;
}

.lowerModuleContainer {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  margin-bottom: 36px;
  background-color: #f3f3f3;
}

.lowerModule {
  display: flex;
  max-width: 1440px;
  height: 324px;
  margin: 0 auto;
  width: calc(100% - 2 * var(--sassy-layout-offset));
}

.shop {
  text-align: center;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}