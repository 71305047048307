.wrapper {
  border-right: 1px solid #ddd;
  flex: 1 0 60%;
  max-width: 752px;
  margin-right: 72px;
  padding-right: 72px;
}

.headerWrapper {
  margin: 18px 0 36px;
}

.img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgWrapper {
  background: #fff;
  height: 390px;
  position: relative;
}

.browseMoreWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlayWrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.overlay {
  opacity: 0;
  justify-content: center;
  align-items: center;
  transition: opacity .2s;
  display: flex;
  position: absolute;
  inset: 0;
}

.overlay:hover {
  opacity: 1;
}

.relatedSearchContainer {
  flex-wrap: wrap;
  display: flex;
}
