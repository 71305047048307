.info {
  flex-direction: row;
  margin-bottom: 36px;
  padding: 0 0 18px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.photo {
  border-radius: 50%;
  max-width: 400px;
  height: 220px;
  margin-bottom: 18px;
  margin-right: 36px;
}

.title {
  text-transform: uppercase;
  margin-bottom: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.description {
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.description a {
  color: #222;
}

.description a:hover {
  color: #444;
}

.description p {
  margin: 0 0 27px;
}

.description ul {
  margin-bottom: 27px;
  padding-left: 18px;
  list-style: outside;
}

.description ul li:not(:last-child) {
  margin-bottom: 9px;
}

.expandableWrapper {
  flex-direction: column;
  display: flex;
}

.content {
  flex: 1;
  max-width: 868px;
}
