.headerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 27px;
}

.headerLeftWrapper {
  display: flex;
  flex-direction: column;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.redirectNotice {
  padding-bottom: 12px;
}

.searchSuggestions {
  font-size: 14px;
  font-weight: 300;
}
.searchSuggestions.collated {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}
.searchSuggestions.regular {
  padding-bottom: 20px;
}

.tradePromoMessage {
  flex-grow: 1;
  text-align: right;
}

.suspectedTradeUserContainer {
  align-self: flex-end;
  padding-bottom: 9px;
}

.recentlyViewed {
  margin-bottom: 72px;
}

.bottomModule {
  max-width: 1018px;
  margin: 0 auto 36px auto;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 18px 0;
}