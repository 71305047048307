.container {
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.imageContainer {
  width: 310px;
  height: 230px;
  position: relative;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.heading {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 18px 0 5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.subHeading {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.fullWidth {
  width: 100%;
}
