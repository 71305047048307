@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.tile {
  border: 1px solid #e8dfcd;
  flex: 1;
  margin-right: 18px;
  text-decoration: none;
  display: block;
}

.tile:last-child {
  margin-right: 0;
}

.tile:hover .tileOverlay {
  opacity: .7;
  background: #222;
}

.tile:hover .tileHoverMessage {
  display: block;
}

.overlay {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.imageContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  display: flex;
  position: relative;
}

@media (width <= 1024px) {
  .imageContainer {
    height: 108px;
  }
}

.image {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.bgImage {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.tileOverlay {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.priceBucketTileOverlay {
  composes: tileOverlay;
  opacity: .25;
  background: #c2a661;
}

.tileHoverMessage {
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
  color: #fff;
  text-align: center;
  max-width: 65%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  display: none;
  position: absolute;
}

.commonTileHoverMessage {
  composes: tileHoverMessage;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.priceBucketTileHoverMessage {
  composes: tileHoverMessage;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 300;
}

.displayName {
  letter-spacing: -.5px;
  color: #222;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}

.icon {
  width: 40px;
  height: 40px;
  margin: auto;
  padding-bottom: 9px;
  display: block;
}
