.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerWrapper {
  margin: 18px 0 9px;
  text-align: center;
}

.imgWrapper {
  margin-top: 18px;
  position: relative;
  height: 350px;
}

.centeredImgWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.centeredImgWrapper .imgLink {
  position: relative;
  padding: 0 9px;
}
.centeredImgWrapper .imgLink:first-child {
  padding-left: 0;
}
.centeredImgWrapper .imgLink:last-child {
  padding-right: 0;
}

.img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.carouselImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lazyLoad {
  height: 100%;
}

.carouselLazyLoad {
  width: 100%;
  height: 100%;
}

.carouselGradient {
  position: relative;
}
.carouselGradient:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  display: inline-block;
  background: linear-gradient(to right, rgba(243, 243, 243, 0) calc(100% - 45px), #f3f3f3 calc(100% - 9px));
}

.browseMoreTile:last-child {
  max-width: 50% !important;
  min-width: 50% !important;
  background-color: #fff;
  background-clip: content-box;
}

.carouselItem {
  padding: 0 9px;
  display: flex;
  justify-content: center;
}

.overlay {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 9px;
  bottom: 0;
  right: 9px;
}
.overlay:hover {
  opacity: 1;
}

.relatedSearchContainer {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.heartWrapper {
  position: absolute;
  top: 0;
  right: 9px;
}