.wrapper {
  flex: 1 0 60%;
  max-width: 752px;
  border-right: 1px solid #ddd;
  margin-right: 72px;
  padding-right: 72px;
}

.headerWrapper {
  margin: 18px 0 36px;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.imgWrapper {
  height: 390px;
  position: relative;
  background: #fff;
}

.browseMoreWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.overlayWrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 200ms opacity ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay:hover {
  opacity: 1;
}

.relatedSearchContainer {
  display: flex;
  flex-wrap: wrap;
}