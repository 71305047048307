.leftArrowBox {
  z-index: 10;
  padding: 9px 5px;
  position: absolute;
  left: 0;
}

.rightArrowBox {
  z-index: 10;
  padding: 9px 5px;
  position: absolute;
  right: 0;
}

.arrow {
  fill: #000;
  stroke: #000;
  width: 40px;
  height: 40px;
}
