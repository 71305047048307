.headerWrapper {
  text-align: center;
  margin-bottom: 27px;
}

.header {
  letter-spacing: -.5px;
  margin: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.subHeader {
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.topModule {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 45px;
  display: flex;
}

.bannerTileContainer {
  flex: 1;
  max-width: 658px;
  margin: 0 36px;
}

.lowerModuleContainer {
  background-color: #f3f3f3;
  width: 100vw;
  margin-bottom: 36px;
  position: relative;
  left: calc(-50vw + 50%);
}

.lowerModule {
  max-width: 1440px;
  height: 324px;
  width: calc(100% - 2 * var(--sassy-layout-offset));
  margin: 0 auto;
  display: flex;
}

.shop {
  text-align: center;
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}
