.info {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 36px;
  padding: 0 0 18px;
  display: flex;
  flex-direction: row;
}

.photo {
  border-radius: 50%;
  height: 220px;
  margin-bottom: 18px;
  margin-right: 36px;
  max-width: 400px;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.description {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
}
.description a {
  color: #222;
}
.description a:hover {
  color: #444;
}
.description p {
  margin: 0 0 27px;
}
.description ul {
  list-style: outside;
  padding-left: 18px;
  margin-bottom: 27px;
}
.description ul li:not(:last-child) {
  margin-bottom: 9px;
}

.expandableWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  max-width: 868px;
}