.relatedCreatorTile {
  display: flex;
  flex-basis: calc(1 / 3 * 100% - 36px);
  margin: 18px;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #ddd;
  padding: 27px 54px;
}
@media (max-width: 1024px) {
  .relatedCreatorTile {
    padding: 27px;
  }
}

.relatedCreatorReview,
.relatedCreatorName {
  margin-bottom: 18px;
}

.relatedCreatorName {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.followRelatedCreator {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 27px;
  position: relative;
}

.relatedCreatorItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.relatedCreatorItem:first-child {
  margin-right: 18px;
}
.relatedCreatorItem:last-child {
  margin-left: 18px;
}
@media (max-width: 1024px) {
  .relatedCreatorItem:first-child {
    margin-right: 9px;
  }
  .relatedCreatorItem:last-child {
    margin-left: 9px;
  }
}

.relatedCreatorItemImage {
  max-height: 130px;
  max-width: 100%;
}

.shopAllTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-top: 18px;
}

.hideTile {
  display: none;
}

.placeholderClass {
  min-width: 100px;
  min-height: 100px;
  background-color: #fbfbfb;
}