.wrapper {
  justify-content: space-between;
  height: 300px;
  display: flex;
  position: relative;
}

.wrapper.reverse {
  flex-direction: row-reverse;
}

.staticContainer, .htmlContainer {
  max-width: 1440px;
  position: relative;
}

.staticContainer {
  display: block;
}

.htmlContainer.border {
  border: thin solid #ddd;
}

.staticText {
  width: 40%;
  height: 100%;
  position: absolute;
}

.termsLink {
  color: #666;
  cursor: pointer;
  margin-left: 54px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
  transition: color .2s;
  position: absolute;
  bottom: 18px;
}

.termsLink:hover {
  color: #444;
  text-decoration: underline;
}
