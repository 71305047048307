/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  background-color: #f3f3f3;
  margin: 18px 0 72px;
  overflow: hidden;
}

.content {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  padding: 36px 0;
  display: flex;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 72px 0 9px;
  text-align: center;
}

.viewMoreButtonWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
}

.viewMoreButton {
  width: 200px;
}