.container {
  background-color: #f3f3f3;
  color: #222;
  display: flex;
  margin-right: 18px;
  padding: 27px;
  flex: 1 1 0%;
  flex-direction: column;
  max-width: 255px;
  align-self: stretch;
}
.container:last-child {
  margin-right: 0;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.divider {
  border-bottom: solid #222 2px;
  margin: 18px 0 18px;
  width: 70px;
}

.regularFontBody {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.smallFontBody {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.viewMoreButton {
  margin: 18px 0;
}