.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.halfWidth {
  flex: 3;
}
