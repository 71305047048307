@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.container {
  display: flex;
  margin: 27px 0 36px 0;
}
.container.hasFullRowOfTiles {
  justify-content: space-between;
}

.tile {
  text-decoration: none;
  display: flex;
  margin-right: 18px;
  padding: 9px;
  flex: 1 1 0%;
  max-width: 278px;
  flex-direction: column;
  align-self: stretch;
  border: 1px solid #ddd;
}
@media (min-width: 769px) {
  .tile {
    position: relative;
  }
  .tile:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s ease;
    opacity: 0;
    background: #c2a661;
  }
  .tile:hover:after {
    opacity: 0.25;
  }
}
.tile:nth-child(5) {
  margin-right: 0;
}

.imageWrapper {
  height: 130px;
  width: 100%;
  position: relative;
}

.image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0;
  display: flex;
  width: 100%;
  margin-top: 18px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  color: #222;
}