.suggestion {
  margin-right: 6px;
  display: inline-block;
}

.suggestion .suggestionTitle {
  margin-right: 6px;
}

.suggestion:not(:first-child):not(:last-child):after {
  content: ",";
}

.suggestion {
  display: inline-block;
}
