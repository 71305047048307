.wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.justifyFlexStart {
  justify-content: flex-start;
}

.sectionHeader {
  letter-spacing: -.5px;
  margin: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.article {
  justify-content: space-between;
  margin-top: 27px;
  display: flex;
}

.articleElement {
  flex: 1;
  align-items: center;
  height: 133px;
  display: flex;
  position: relative;
}

.imgElement {
  composes: articleElement;
  max-width: 192px;
}

.featuredImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  text-overflow: ellipsis;
  word-break: break-word;
  letter-spacing: -.5px;
  margin: 0 0 0 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@supports (-webkit-line-clamp: 4) {
  .header {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.lazyLoadPlaceholder {
  background-color: #fbfbfb;
  min-width: 100%;
  min-height: 100%;
}

.hideArticle {
  display: none;
}
