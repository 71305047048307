@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-bottom: 27px;
}
.title.loading {
  height: 30px;
  width: 140px;
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.tileContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.tile {
  height: 213px;
  max-width: 426px;
  min-width: 305px;
  width: 100%;
  flex-direction: column;
  padding: 27px;
  margin-right: 18px;
  background-color: #fff;
  text-decoration: none;
}
.tile:last-child {
  margin-right: 0;
}
.tile.loading {
  padding: 0;
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.images {
  width: 100%;
  display: flex;
  max-width: 319px;
  height: 86px;
  margin: 0 auto 27px;
}

.image {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.tileTitle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.shopAll {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-decoration: underline;
  margin-bottom: 27px;
}