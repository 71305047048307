.wrapper {
  margin: -9px 0 36px;
}

.carousel {
  margin-bottom: 18px;
}

.tilesWrapper {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.tile {
  color: #222;
  flex: 1;
  align-items: center;
  margin-right: 18px;
  padding: 18px;
  text-decoration: none;
  display: flex;
  position: relative;
  border: 1px solid #ddd !important;
}

.tile:last-child {
  margin-right: 0;
}

@media (width <= 1024px) {
  .tile {
    flex-direction: column;
    justify-content: center;
  }
}

.tile:after {
  content: "";
  opacity: 0;
  background-color: #c2a661;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.tile:hover {
  color: #222;
}

.tile:hover:after {
  opacity: .25;
}

.tileImage {
  width: 72px;
  height: 72px;
  margin-right: 18px;
  position: relative;
}

@media (width <= 1024px) {
  .tileImage {
    margin-right: 0;
  }
}

.tileTitle {
  letter-spacing: -.5px;
  text-align: center;
  word-break: break-word;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25;
  text-decoration: none;
}

@media (width <= 1024px) {
  .tileTitle {
    margin-top: 18px;
  }
}
