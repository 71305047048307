.wrapper {
  min-height: 400px;
  margin-bottom: 36px;
  padding: 45px;
  display: flex;
  position: relative;
}

.imgWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.image {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.moduleWrapper {
  z-index: 1;
  background-color: #fff;
  width: calc(100% - 230px);
  margin: auto;
  padding: 0 18px;
  position: relative;
}

.module {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 18px;
}

.module:empty {
  padding-top: 0;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.subtitle {
  letter-spacing: -.5px;
  color: #222;
  margin-bottom: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.description {
  color: #222;
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.link {
  margin-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
}

.arrow {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
