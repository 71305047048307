.wrapper {
  margin: 0;
}

.bottomWrapper {
  margin: 27px 0;
}

@media (width >= 569px) {
  .bottomWrapper {
    flex-direction: column;
    align-items: center;
    margin: 27px 0 72px;
    display: flex;
  }
}

.relatedSearchesHeader {
  letter-spacing: -.5px;
  margin-bottom: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width >= 569px) {
  .relatedSearchesHeader {
    letter-spacing: -.5px;
    margin-bottom: 18px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.itemsWrapper {
  margin-top: -18px;
  margin-bottom: 27px;
}
