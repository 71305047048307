.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.seeMore {
  margin: 18px 0;
  text-align: center;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.chairLamp {
  width: 80px;
  height: 80px;
}

.browseMoreButton {
  z-index: 10;
}